<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6">
        <site-group-filter store="audience" class="ml-8" />
      </v-col>
      <v-col cols="6">
        <site-filter store="audience" class="ml-4 mr-8" />
      </v-col>
      <v-col cols="6">
        <source-filter
          class="mt-2 ml-8"
          :menu-props="{ maxHeight: '300', zIndex: 13 }"
        ></source-filter>
      </v-col>
      <v-col cols="3">
        <year-filter
          class="mt-2 mr-8 ml-4"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
        ></year-filter>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-spacer />
        <apply-filters
          :disabled="isDisabled"
          outlined
          color="secondary"
          class="mr-8"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import SourceFilter from "@/components/audience/by-source/filters/SourceFilter.vue";
import YearFilter from "@/components/audience/by-source/filters/YearFilter.vue";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    SiteFilter,
    SiteGroupFilter,
    SourceFilter,
    YearFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
  computed: {
    getSites() {
      return this.$store.getters["audience/getSites"];
    },
    getYear() {
      return this.$store.getters["audience/getYearDate"];
    },
    getSourceId() {
      return this.$store.getters["audience/getSourceId"];
    },
    getListSourceId() {
      return this.$store.getters["audience/getListSourceId"];
    },
    getExactSearch() {
      return this.$store.getters["audience/getExactSearch"];
    },
    sourceMediumId() {
      return this.getExactSearch
        ? this.getListSourceId.join(",")
        : this.getSourceId.toString();
    },
    isDisabled() {
      return (
        this.isLoading ||
        this.getSites.length === 0 ||
        !this.getYear ||
        this.sourceMediumId.length === 0
      );
    },
  },
};
</script>

<style></style>
