var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v("Audience par source")]),_c('v-card',{staticClass:"pt-8"},[_c('filters')],1),_c('v-card',{staticClass:"mt-8"},[_c('v-card-text',{staticClass:"d-flex flex-wrap pb-4"},[_c('div',{staticClass:"results-container d-flex flex-row"},[_c('v-data-table',{staticClass:"elevation-1 results-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items}}),_c('dot-menu',{attrs:{"csvButton":{
            csvData: _vm.csvData,
            filename: ("export_" + (this.getYear) + "_audience_source.csv"),
            disabled: _vm.exportDisabled,
          },"excelButton":{
            excelData: _vm.excelData,
            filename: ("export_" + (this.getYear) + "_audience_source.xlsx"),
            disabled: _vm.exportDisabled,
          }},on:{"exportCsv":_vm.exportCSV,"exportExcel":_vm.exportExcel}})],1)])],1),_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Vous recherchez les sources contenant : "),_c('strong',[_vm._v(_vm._s(this.getContainsSource))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }